.hero {
  position: relative;
  background-image: url("https://main.wpresidence.net/wp-content/uploads/2024/01/wpresidece-header.webp");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90vh;
  width: 100%;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 1; /* Ensure the overlay is above the background image */
}
.hero .container {
  padding-top: 15%;
}
.hero h1 {
  color: #fff;
  font-size: 60px;
}
.hero p {
  color: #fff;
  opacity: 0.8;
}

@media screen and (max-width: 800px) {
  .hero .container {
    padding-top: 30%;
  }
  form {
    display: flex;
    flex-wrap: wrap;
  }
  form .box {
    border-left: none;
  }
  form input {
    width: 100%;
  }
}
