.buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttons button {
  margin: 0 10px;
  padding: 20px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background-color: #27ae60;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Add this to your RecentCard.css */
.card-link .box {
  transition: transform 0.3s ease;
}

.card-link:hover .box {
  transform: scale(1.05);
}

.card-link .box img {
  transition: transform 0.3s ease;
}

.card-link:hover .box img {
  transform: scale(1.1);
}
