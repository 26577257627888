.featured .box {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  border-radius: 6px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
}
.featured img {
  width: 65px;
  height: 65px;
  margin: auto;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hover-rotate img {
  transition: transform 0.5s ease-in-out;
}

.hover-rotate:hover img {
  animation: rotate360 1s linear infinite;
}
